import { inject, Injectable } from '@angular/core';
import { Data, Route, RouterStateSnapshot, Router, ActivatedRouteSnapshot } from '@angular/router';

import { Permission, Feature } from '@bp/shared/domains/permissions';
import { isEmpty } from '@bp/shared/utilities/core';
import { PERMISSION_BASED_REDIRECTION_ON_NO_ACCESS_TOKEN } from '@bp/shared/domains/jwt-session';

import { FeaturePermissionsService, PermissionBasedNavigationService } from '../services';

import { IdentityLoggedInGuard } from './identity-logged-in.guard';
import { IDENTITY_GET_ACCESS_DIALOG_GUARDS } from './identity-get-access-dialog-guard';

export type IdentityHasAccessGuardConfig = {
	permission: Permission;
	permissionBasedRedirectionOnNoAccess?: boolean;
	dontRequireRunGuardsAlways?: boolean;
};

export function identityHasAccessGuardConfig(
	permissionOrConfig: IdentityHasAccessGuardConfig | IdentityHasAccessGuardConfig['permission'],
): IdentityHasAccessGuardConfig {

	return Feature.isValid(permissionOrConfig)
		? {
			permission: permissionOrConfig,
		}
		: permissionOrConfig;
}

@Injectable({
	providedIn: 'root',
})
export class IdentityHasAccessGuard {

	private readonly __router = inject(Router);

	private readonly __identityLoggedInGuard = inject(IdentityLoggedInGuard);

	private readonly __featurePermissionsService = inject(FeaturePermissionsService);

	private readonly __permissionBasedNavigationService = inject(PermissionBasedNavigationService);

	private readonly __identityGetAccessDialogGuards = inject(
		IDENTITY_GET_ACCESS_DIALOG_GUARDS,
		{ optional: true },
	);

	async canActivate(route: ActivatedRouteSnapshot | Route, state: RouterStateSnapshot): Promise<boolean> {
		const userIsLoggedIn = await this.__userIsLoggedIn(<ActivatedRouteSnapshot>route, state);

		if (!userIsLoggedIn)
			return false;

		const { data } = route;

		this.__assertIdentityHasAccessGuardConfig(data);

		this.__assertRouteIsSetupProperly(route, data);

		const hasAccess = this.__featurePermissionsService.hasAccess(data.permission);

		if (!hasAccess) {
			this.__tryToNavigateToNextAccessibleRouteOrShowForbiddenPage(data);

			return false;
		}

		return this.__checkGetAccessDialogs(data.permission);
	}

	private __tryToNavigateToNextAccessibleRouteOrShowForbiddenPage(
		{ permissionBasedRedirectionOnNoAccess }: IdentityHasAccessGuardConfig,
	): void {
		permissionBasedRedirectionOnNoAccess = this.__router.getCurrentNavigation()?.extras.state === PERMISSION_BASED_REDIRECTION_ON_NO_ACCESS_TOKEN || permissionBasedRedirectionOnNoAccess;

		if (permissionBasedRedirectionOnNoAccess) {
			const isSuccess = this.__permissionBasedNavigationService.tryNavigate();

			if (isSuccess)
				return;
		}

		this.__navigateToForbiddenPage();
	}

	private async __checkGetAccessDialogs(permission: Permission): Promise<boolean> {
		if (isEmpty(this.__identityGetAccessDialogGuards))
			return true;

		const dialogsGetAccessRequests = this.__identityGetAccessDialogGuards.map(
			async dialogGuard => dialogGuard.getAccess(permission),
		);

		const getAccessResults = await Promise.all(dialogsGetAccessRequests);

		return getAccessResults.every(Boolean);
	}

	private __assertRouteIsSetupProperly(
		route: ActivatedRouteSnapshot | Route,
		{ dontRequireRunGuardsAlways }: IdentityHasAccessGuardConfig,
	): void {
		const runGuardsAndResolvers
			= route instanceof ActivatedRouteSnapshot
				? route.routeConfig?.runGuardsAndResolvers
				: route.runGuardsAndResolvers;

		if (dontRequireRunGuardsAlways || runGuardsAndResolvers === 'always')
			return;

		// Since the permission to the route can change dynamically, we need to make sure that we run the guards always

		throw new Error(
			'Route guarded with `IdentityHasAccessGuard` must always be configured with `runGuardsAndResolvers: always`',
		);
	}

	private __navigateToForbiddenPage(): void {
		void this.__router.navigate([ 'forbidden' ], { replaceUrl: false, skipLocationChange: true });
	}

	private async __userIsLoggedIn(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		return this.__identityLoggedInGuard.canActivate(route, state);
	}

	private __assertIdentityHasAccessGuardConfig(data: Data | undefined): asserts data is IdentityHasAccessGuardConfig {
		const { permission } = <IdentityHasAccessGuardConfig>data;

		if (Feature.isValid(permission))
			return;

		throw new Error(
			'`IdentityHasAccessGuard` must always come with the `permission` property declared on the route config data property',
		);
	}
}
