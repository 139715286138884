import { firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { IIdentity } from '@bp/shared/domains/jwt-session';

import { LOGIN_ROUTE_PATHNAME_TOKEN } from '../injection-tokens';
import { IdentityFacade } from '../state/identity.facade';

@Injectable({
	providedIn: 'root',
})
export class IdentityLoggedInGuard {
	constructor(
		@Inject(LOGIN_ROUTE_PATHNAME_TOKEN) private readonly _loginPageUrl: string,
		private readonly _identityFacade: IdentityFacade<IIdentity, any>,
		private readonly _router: Router,
	) {}

	async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		await firstValueFrom(this._identityFacade.pending$.pipe(filter(v => !v)));

		const user = await firstValueFrom(this._identityFacade.user$);

		if (user)
			return true;

		this._identityFacade.saveUrlForRedirectionAfterLogin(state.url);

		void this._router.navigateByUrl(this._loginPageUrl);

		return false;
	}
}
